import React from "react";

import { AnalyticsBrowser } from "@segment/analytics-next";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

// we can export this instance to share with rest of our codebase.
export const analytics = AnalyticsBrowser.load({ writeKey: "ZBOUEWsxz1AHWsAOjx8NXebg32P1yjWw" });

export default () => {
  // Initialize with fingerprint

  // Track user events

  // Identify user information

  const { isLoading, error, data } = useVisitorData();
  console.log(data);

  return (
    <body className="flex flex-col w-full h-screen items-center justify-center gap-5 ">
      <h1>Hello</h1>
      <button
        className="rounded border border-gray-400 shadow bg-green-300 hover:bg-green-400 px-3 py-1 font-medium"
        onClick={() => analytics.track("hello world")}
      >
        Track from Client
      </button>
      <button
        className="rounded border border-gray-400 shadow bg-blue-300 hover:bg-blue-400 px-3 py-1 font-medium"
        onClick={() =>
          fetch("/api/analytics/send", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ fingerprint: data?.visitorId }),
          })
        }
      >
        Track from Server
      </button>

      {data && <div>Welcome {data.visitorFound && `back ${data.visitorId}`}</div>}
    </body>
  );
};

export function Head() {
  return (
    <>
      <title>Test Page</title>
      <meta name="description" content="Hello World" />
    </>
  );
}
